<template>
    <v-container class="px-0 mr-0">
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-data-table
        :search="nameSearchable"
        v-if="this.teams!=null"
        :items="teams"
        :headers = "headersTeam"
        dense
        class="elevation-1 mt-5">
        <template v-slot:item.created_at="{item}">
            <span v-if="item.created_at!=null">
                {{ item.created_at.split('T')[0] }}

                {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}}

            </span>
        </template>
        <template v-slot:item.name="{item}">
            <a @click="redirectReadTeam(item.name)" class="text-decoration-none">
                {{ item.name }}
            </a>
        </template>
        <template v-slot:top>
            <v-toolbar width="auto" height="auto" color="py-3" flat>
                <v-row no-gutters>
                    <v-col class="mt-3 mx-3">
                        <v-row class="mb-3">
                            <!-- <v-icon large color="black" class="px-2">
                                fa-user-circle
                            </v-icon> -->
                            <v-toolbar-title class="font-weight-bold text-h4">
                                Team
                            </v-toolbar-title>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-3">
                            <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="nameSearchable"
                            label="Search">
                            </v-text-field>
                        </v-row>
                    </v-col>
                    <v-btn v-if="userRole=='operation'||userRole=='sales_manager'" color="primary" @click="redirectNewTeam">
                    New
                    </v-btn>
                </v-row>
            </v-toolbar>
            
        </template>
        </v-data-table>
    </v-container>
</template>
<script>
export default{
    componets:{

    },
    created(){
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getAllTeam"){
                this.teams = resp.data;
            }
            this.api.isSuccesful = true;
            this.api.isError = false;
            this.api.isLoading = false;

        }

    },
    mounted()
    {
        this.fetch();
    },
    data:()=>({
    nameSearchable:null,
    userRole:null,
    teams:null,
    headersTeam:[
        {
            text:'Name',
            value:'name',
        },
        {
            text:'Description',
            value:'description'
        },
        {
            text:'Created date',
            value:'created_at',
        },
        {
            text:'Total members',
            value:'total_members',
        }

    ],
    api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        },
    }),
    methods:{
        fetchTeam()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/team";
            if(this.userRole=='sales_team_lead'){
                tempApi.url = process.env.VUE_APP_SERVER_API+"/team/leader/"+this.$store.getters.getEmail;
            }
            return tempApi;
        },
        fetchCurrentUserRole(){
            this.userRole = this.$store.getters.getRole;
        },
        fetch()
        {  
            this.fetchCurrentUserRole();
            let fetchTeamApi = this.fetchTeam();
            this.$axios.all([
                this.$api.fetch(fetchTeamApi),
            ]);
        },
        redirectNewTeam()
        {
            this.$router.push({name:'PageTeamCreate'});
        },
        redirectReadTeam(teamName)
        {
            this.$store.commit("updateTeam",teamName);
            this.$router.push({
                name:'PageTeamDetail',
            });

        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
    },
}
</script>